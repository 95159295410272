import styled from 'styled-components';
import { Layout } from 'antd';
import { COLOR } from 'util/theme';

export const CustomLayout = styled(Layout)``;

export const Logo = styled.div`
  width: 110px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 0.5rem;
  margin-right: 3.5rem;
  span {
    margin-left: 0.5rem;
  }
`;

export const RightMenu = styled.div`
  display: flex;
  align-items: center;

  /* TODO: remove later */
  position: relative;
  top: -0.75rem;
  margin-top: 1.5rem;
  padding: 0.75rem;
  border: 1px solid ${COLOR.GREY_2};
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 50px;
  .socials {
    display: flex;
    column-gap: 28px;
  }
`;
