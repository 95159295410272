import { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Footer from './Footer';
import { CustomLayout, Logo, RightMenu } from './styles';

const { Header, Content } = Layout;

const navItems = [
  { key: 'posts', name: 'Blogs' },
  { key: 'ideas', name: 'Ideas' },
  { key: 'team-members', name: 'Team' },
  { key: 'alter-orbis-submissions', name: 'Alter orbis submissions' },
  { key: 'contribution-opportunities', name: 'Contribution Opportunities' },
  { key: 'media-appearances', name: 'Press' },
  { key: 'ecosystem-services', name: 'Ecosystem Services' },
];

const NavigationBar = ({ children }) => {
  const router = useRouter();
  const [selectedMenu, setSelectedMenu] = useState([]);
  const { pathname } = router;

  /* to set default menu on first render */
  useEffect(() => {
    if (pathname) {
      const name = pathname.split('/')[1];
      setSelectedMenu(name || 'posts');
    }
  }, [pathname]);

  const handleMenuItemClick = ({ key }) => {
    /* `posts` is the homepage hence '' */
    const pushTo = key === 'components' ? '' : key;
    router.push(`/${pushTo}`);
    setSelectedMenu(key);
  };

  return (
    <CustomLayout>
      <Header>
        <Logo onClick={() => router.push('/')}>
          <span>CMS</span>
        </Logo>

        <Menu theme="light" mode="horizontal" selectedKeys={[selectedMenu]}>
          {navItems.map(({ key, name }) => (
            <Menu.Item key={key} onClick={handleMenuItemClick}>
              {name}
            </Menu.Item>
          ))}
        </Menu>

        <RightMenu>XXX</RightMenu>
      </Header>

      <Content className="site-layout">
        <div className="site-layout-background">{children}</div>
      </Content>

      <Footer />
    </CustomLayout>
  );
};

NavigationBar.propTypes = {
  children: PropTypes.element,
};

NavigationBar.defaultProps = {
  children: null,
};

export default NavigationBar;
